<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-15 23:04:35
-->
<template>
  <div class="main module-wrap">
    <el-form :label-position="labelPosition" label-width="120px"
      :model="formData">
      <div class="head-input flex fsbc">
        <div class="input-item">
          <el-form-item label="项目名称：" prop="projectUuid" required>
            <ProSelect :scene="0" :width="200"
            v-model="formData.projectUuid" @handlerInfo="handlerProInfo"/>
          </el-form-item>
          <el-form-item label="本次分配：" prop="projectUuid" required>
            <el-input placeholder="请输入票数" v-model="formData.count">
              <template slot="append">张</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="分配编号：">
            确定时自动生成
          </el-form-item>
          <el-form-item label="当前余票：">
            {{formData.remainCoupon}}张
          </el-form-item>
        </div>
      </div>
      <el-form-item label="备注：">
        <el-input v-model="formData.remark" placeholder="请输入备注内容" />
      </el-form-item>
      <UploadFile
      :list="fileList" @onChange="onChange" style="margin-left: 54px"/>
      <div class="head-input flex fsbc">
        <div class="input-item">
          <el-form-item label="制单时间：">
            确定时自动生成
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="操作人员：">
            确定时自动生成
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  $gp_groupAllocate,
} from '../../api/index';
import { globalClose } from '../../utils/utils';
import UploadFile from '../../components/UploadFile.vue';
import ProSelect from '../../components/ProSelect.vue';

export default {
  name: 'TicketDistribution',
  components: { UploadFile, ProSelect },
  data() {
    return {
      labelPosition: 'right',
      formData: {
        remark: '',
        tableData: [],
      },
      fileList: [],
      refreshInfo: this.refreshInfo,
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
        if (val.tag === 'onConfirm') { this.groupAllocate(); }
        if (val.tag === 'onCancel') { this.refreshData(); }
      }
    },
  },
  methods: {

    handlerProInfo(val) {
      this.formData.remainCoupon = val.remainCoupon;
    },
    // 分配票据
    groupAllocate() {
      const { remark, count, projectUuid } = this.formData;
      if (!projectUuid) {
        this.$message.warning('请选择项目');
        return;
      }
      if (!count) {
        this.$message.warning('请输入分配数量');
        return;
      }

      $gp_groupAllocate({
        projectUuid, remark, count, fileList: this.fileList,
      }).then(() => {
        this.formData.remark = '';
        this.$parent.groupAllocateRecordList();
        this.$router.back();
      });
    },
    refreshData() {
      this.$router.back();
    },
    onChange(val) {
      this.fileList = val;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  .input-item{
    width: 48%;
  }
  .submit-area{
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 20px;
    padding: 10px;
    text-align: center;
    background: #fff;
    border-top: 1px solid #eee;
  }
  </style>
