<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-16 00:01:21
-->
<template>
  <div class="main module-wrap">
    <el-form :label-position="labelPosition" label-width="120px"
      :model="formData">
      <div class="head-input flex fsbc">
        <div class="input-item">
          <el-form-item label="项目名称" prop="projectUuid" required>
            {{formData.projectName}}
          </el-form-item>
          <el-form-item label="本次分配：" prop="projectUuid" required>
            {{formData.count}}
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="分配编号：">
            {{formData.no}}
          </el-form-item>
          <el-form-item label="当前余票：">
            {{formData.remainCount}}张
          </el-form-item>
        </div>
      </div>
      <el-form-item label="备注：">
        {{formData.remark}}
      </el-form-item>
      <UploadFile :scene="2" :list="formData.fileList" style="margin-left: 10px"/>
      <div class="head-input flex fsbc">
        <div class="input-item">
          <el-form-item label="制单时间：">
            {{formData.createTime}}
          </el-form-item>
          <el-form-item label="审批时间：" v-if="isApprover">
            {{formData.approveTime}}
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="操作人员：">
            {{formData.createBy}}
          </el-form-item>
          <el-form-item label="审批人员：" v-if="isApprover">
            {{formData.approveBy}}
          </el-form-item>
        </div>
      </div>
      <el-form-item label="审批结果:" v-if="isApprover">
          <span v-if="isOnlyBack">{{ formatState(formData.applyStatus) }}</span>
          <el-select v-else v-model="formData.applyPass">
            <el-option
              v-for="state in resultState"
              :value="state.value"
              :label="state.label"
              :key="state.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见" v-if="isApprover">
          <span v-if="isOnlyBack">{{ formData.applyComment }}</span>
          <el-input
            v-else
            v-model="formData.applyComment"
            placeholder="请输入审批备注（未通过时必填）"
            maxlength="50"
            show-word-limit
            type="text" />
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  $gp_groupCouponApprove, $gp_getAllocateDetail,
} from '../../api/index';
import { globalClose } from '../../utils/utils';
import UploadFile from '../../components/UploadFile.vue';

export default {
  name: 'TicketDistribution',
  components: { UploadFile },
  data() {
    return {
      labelPosition: 'right',
      formData: {
        remark: '',
        fileList: [],
        applyPass: false,
      },
      refreshInfo: this.refreshInfo,
      resultState: [
        { value: true, label: '已通过' },
        { value: false, label: '未通过' },
      ],
      isOnlyBack: false,
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isApprover() {
      return this.$route.name === 'AssignApproverChecked';
    },
  },
  watch: {
    updateTime(val) {
      console.log('val', val);
      if (val.tag === 'refresh') { this.refreshData(); }
      if (val.tag === 'close') { globalClose(this); }
      if (val.tag === 'onConfirm') { this.groupAllocate(); }
      if (val.tag === 'onCancel') { this.refreshData(); }
    },
  },
  methods: {
    getDetail() {
      const { applyUuid } = this;
      $gp_getAllocateDetail({ applyUuid }).then((res) => {
        this.formData = res;
        console.log('this.formData', this.formData);
        // if (res.status === 2) {
        //   this.formData.applyPass = true;
        // } else {
        //   this.formData.applyPass = false;
        // }
      });
    },
    formatState(state) {
      console.log('state==', state);
      const status = {
        1: '待审批',
        2: '已通过',
        3: '已拒绝',
      };
      return status[state];
    },
    // 分配票据
    groupAllocate() {
      const { applyPass, applyComment, applyUuid } = this.formData;
      if (!applyPass && !applyComment) {
        this.$message.error('请填写审批意见');
        return;
      }
      $gp_groupCouponApprove({
        applyUuid, applyPass, applyComment,
      }).then(() => {
        this.$parent.groupAllocateRecordList();
        this.$router.back();
      });
    },
    refreshData() {
      this.$router.back();
    },
    onChange(val) {
      this.fileList = val;
    },
  },
  mounted() {
    this.applyUuid = this.$route.query.applyUuid;
    this.isOnlyBack = Boolean(this.$route.query.onlyBack);
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
  .input-item{
    width: 48%;
  }
  .submit-area{
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 20px;
    padding: 10px;
    text-align: center;
    background: #fff;
    border-top: 1px solid #eee;
  }
  </style>
