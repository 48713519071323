<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-15 23:56:12
-->
<template>
  <div class="main">
    <div class="print-com module-wrap" v-if="showCom">
      <el-form
        :inline="true"
        :label-position="labelPosition"
        label-width="90px"
        :model="formData"
      >
        <el-form-item label="年份">
          <el-select class="w160" v-model="formData.year" @change="getDays" placeholder="请选择年份">
            <el-option :label="year.label"
            :value="year.value" v-for="year in years"
            :key="year.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="月份">
          <el-select class="w160"
          clearable
          v-model="formData.month"
          @change="getDays"
          placeholder="请选择月份">
            <el-option :label="month.label"
            :value="month.value" v-for="month in months"
            :key="month.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="日期" v-if="userInfo.role != 'GROUP_MANAGER'">
          <el-select class="w160" v-model="formData.day" placeholder="请选择日期">
            <el-option :label="day.label"
            :value="day.value" v-for="day in days"
            :key="day.value" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="审批状态">
          <el-select class="w160" v-model="formData.applyStatus" placeholder="选择状态">
            <el-option :label="state.label"
            :value="state.value" v-for="state in status"
            :key="state.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input v-model="formData.projectName" placeholder="请输入项目名称" />
        </el-form-item>
        <el-form-item class="center">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" plain @click="resetData">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="add" v-if="!isApprover">添加分票</el-button>
      <div class="table-data mt20">
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
        <el-table-column width="100" label="分票编号">
            <template slot-scope="scope">
              <span class="theme-color pointer"
                @click="toPage(scope.row, 'onlyBack')">
                {{scope.row.no}}
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="no" width="160" label="分票编号" /> -->
          <el-table-column prop="createTime" width="160" label="操作时间" />
          <el-table-column prop="projectName" label="分配项目" />
          <el-table-column prop="count" width="160" label="本次分配总数（张）" />
          <el-table-column prop="remark" label="备注" />
          <el-table-column prop="createBy" width="100" label="操作员" />
          <el-table-column width="100" label="审批状态">
            <template slot-scope="scope">
              <span :class="getStateInfo(scope.row.applyStatus).color">
                {{getStateInfo(scope.row.applyStatus).desc}}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100" label="操作" center v-if="isApprover">
            <template slot-scope="scope">
              <span class="theme-color pointer"
                v-if="scope.row.applyStatus == 1"
                @click="toPage(scope.row)">
                审批
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
             background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalClose, formatDate } from '../../utils/utils';
import { createYears, createMonths, createDays } from '../../utils/index';
import { $gp_proAllocateRecordList } from '../../api/index';

export default {
  name: 'TicketRecord',
  data() {
    return {
      labelPosition: 'right',
      count: 0,
      years: [],
      months: [],
      days: [],
      formData: {
        size: 10,
        page: 1,
      },
      tableData: [],
      status: [
        { label: '全部', value: null },
        { label: '待审批', value: 1 },
        { label: '已通过', value: 2 },
        { label: '已拒绝', value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    // 组件是否显示
    showCom() {
      const comNames = ['TicketRecord', 'AssignApprover'];
      const comName = this.$route.name;
      return comNames.includes(comName);
    },
    isApprover() {
      return this.$route.name === 'AssignApprover';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
    // $route() {
    //   // 监听路由是否变化
    //   this.groupAllocateRecordList();
    // },
  },
  created() {
    this.years = createYears(5);
    this.months = createMonths();
    this.formData.year = this.years[0].value;
  },
  methods: {
    groupAllocateRecordList() {
      $gp_proAllocateRecordList(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
        console.log(res);
      });
    },
    add() {
      this.$router.push('/ticketRecord/ticketDistribution');
    },
    toPage({ applyUuid }, onlyBack) {
      let path = '/ticketRecord/ticketDistributionDetail';
      if (this.isApprover) {
        path = '/assignApprover/assignApproverChecked';
        if (onlyBack) {
          this.$router.push({ path, query: { applyUuid, onlyBack } });
          return;
        }
      }
      this.$router.push({ path, query: { applyUuid } });
    },
    formatTime({ time }) {
      const timeStr = formatDate(time);
      return timeStr;
    },
    search() {
      console.log('search');
      this.groupAllocateRecordList();
    },
    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.groupAllocateRecordList();
    },
    resetData() {
      this.formData = {
        page: 1,
        size: 10,
        year: this.years[0].value,
        month: '',
        day: '',
      };
    },
    refreshData() {
      this.groupAllocateRecordList();
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    getStateInfo(state) {
      const result = state || 1;
      const states = {
        1: { color: 'blue', desc: '待审批' },
        2: { color: 'theme-color', desc: '已通过' },
        3: { color: 'red', desc: '未通过' },
      };
      return states[result];
    },
  },
  mounted() {
    this.groupAllocateRecordList();
  },
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 48%;
}
.submit-area {
  padding: 20px;
  text-align: center;
}
</style>
