<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-06 21:08:34
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div class="module-wrap">
    <div class="main-com" v-if="$route.name === 'TicketCount'">
      <el-form
            :inline="true"
            label-width="90px"
            :model="formData"
          >
            <el-form-item label="项目名称:">
              <el-input v-model="formData.projectName" clear class="w500"/>
            </el-form-item>
            <el-form-item class="center">
              <el-button type="primary" @click="search">查询</el-button>
              <el-button type="primary" plain @click="formData.projectName = ''">重置</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="tableData"
            border
            show-summary
            :header-cell-style="$rowClass"
            style="width: 100%">
            <el-table-column prop="projectName" label="项目名称" />
            <el-table-column prop="customer" label="客户单位" />
            <el-table-column prop="total" label="已分得" />
            <el-table-column prop="used" label="已使用" />
            <el-table-column prop="remain" label="未使用" />
          </el-table>
          <div class="pagination-block">
            <el-pagination
              background
              :current-page.sync="formData.page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              >
            </el-pagination>
          </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  $gp_groupProStatistics,
} from '../../api/index';

export default {
  name: 'TicketCount',
  data() {
    return {
      visable: 1,
      count: 4,
      formData: {
        page: 1,
        size: 10,
      },
      tableData: [],
      activeName: 'first',
      curTimeBtn: 1,
      totalData: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
      console.log(this.activeName);
      this.formData.page = 1;
      this.getData();
    },
    getData() {
      if (this.activeName === 'first') {
        $gp_groupProStatistics(this.formData).then((res) => {
          this.tableData = res.list;
          this.totalData = res.total;
          this.count = res.totalCount;
        });
      }
    },
    search() {
      console.log('search');
      this.getData();
    },
    handlerCurPage(val) {
      this.formData.page = val;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .w500{
    width: 500px;
  }
  .module-wrap{
    position: relative;
    .count-detail{
      position: absolute;
      right: 22px;
      top: 30px;
      font-size: 14px;
      z-index: 10;
      cursor: pointer;
    }
  }
</style>
